<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบติดตั้ง : สำหรับผู้ขาย';
			const content = `
				<p>มีกรณีการคืนเงิน-คืนสินค้า ดังนี้</p>
				<ol>
					<li>ยกเลิกก่อนติดตั้ง <strong>กรณีลูกค้า ยกเลิกคำสั่งซื้อ</strong>
						<ul>
							<li>ลูกค้ายกเลิกภายในวันที่สั่งซื้อ</li>
							<li>ลูกค้ายกเลิก ก่อนติดตั้ง 2 วัน</li>
						</ul>
					</li>
					<li>
						<strong>วันที่ติดตั้ง</strong>
						<strong> - กรณีลูกค้า สั่งสินค้าผิดขนาด-ผิดรุ่น</strong>
						<ul>
							<li>ลูกค้า สั่งสินค้าผิดขนาด-ผิดรุ่น และ เปลี่ยนสินค้าอื่นในร้าน</li>
							<li>สั่งสินค้าผิดขนาด-ผิดรุ่น และ ลูกค้ายกเลิกรายการ</li>
						</ul>
					</li>
					<li>
						<strong>วันที่ติดตั้ง</strong>
						<strong> - กรณีร้านค้า ไม่มีสินค้า</strong>
					</li>
				</ol>
				<hr />
				<ol>
					<li>
						<strong> ลูกค้ายกเลิกรายการซื้อ </strong>
						<br />
						<br />
						<strong>ลูกค้ายกเลิก</strong> ภายในวันที่สั่งซื้อสินค้า (ก่อน 20.00 น.) *ซื้อหลังเวลา 20.00 น. ให้นับเป็นรอบถัดไป
					</li>
				</ol>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ภายในวันที่สั่งซื้อสินค้า (ก่อน </strong>
										<strong>20.00 น.) <br />
										</strong>*ซื้อหลังเวลา 20.00 น. ให้นับเป็นรอบถัดไป
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>คืนวงเงินเข้าบัตรเต็มจำนวน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>คืนวงเงินเข้าบัตรเต็มจำนวน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ด้วยการโอน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 7 วัน นับหลังจากวันรับแจ้งเรื่องจากลูกค้า</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าธรรมเนียมอื่น</p>
								</td>
								<td>
									<p>YELLOWTIRE : รับผิดชอบค่าธรรมเนียม (ถ้ามี)</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					<strong>ลูกค้ายกเลิกรายการซื้อ </strong>ก่อนวันติดตั้ง 2 วัน
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ยกเลิก <br /> ก่อนติดตั้ง 2 วัน </strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่าย ให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 15% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 14 วัน นับหลังจากวันรับแจ้งเรื่องจากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินให้ร้านค้าตามรอบบัญชีที่กำหนด</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ol start="2">
					<li>
						<strong> วันที่ติดตั้ง </strong>
						<strong>&ndash; กรณี ลูกค้าสั่งสินค้าผิดขนาด-ผิดรุ่น</strong>
					</li>
				</ol>
				<p>
					<strong>ลูกค้าสั่งสินค้าผิดขนาด-ผิดรุ่น</strong> และ เปลี่ยนสินค้าอื่นในร้าน
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>ประเภทการชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>เปลี่ยนสินค้าอื่นในร้าน <br />
										</strong>
										<strong>และยกเลิกรายการสั่งซื้อเดิม</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10%</p>
								</td>
								<td>
									<p>YELLOWTIRE&nbsp; จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20%</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 15% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10%</p>
								</td>
								<td>
									<p>YELLOWTIRE&nbsp; จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาคืนเงิน</p>
								</td>
								<td>
									<p>ภายใน 14 วันนับหลังจากวันที่รับแจ้งเรื่อง</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินให้ร้านค้าตามรอบบัญชีที่กำหนด</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าธรรมเนียมอื่น</p>
								</td>
								<td>
									<p>YELLOWTIRE : รับผิดชอบค่าธรรมเนียม (ถ้ามี)</p>
								</td>
								<td>
									<p>&nbsp;</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					<strong>ลูกค้าสั่งสินค้าผิดขนาด-ผิดรุ่น</strong> และ ลูกค้ายกเลิกรายการ
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>ประเภทการชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ไม่เปลี่ยน <br />
										</strong>
										<strong>และ</strong>
										<strong>ยกเลิกรายการสั่งซื้อเดิม</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20%</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 5% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 30%</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 15% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20%</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 5% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาคืนเงิน</p>
								</td>
								<td>
									<p>ภายใน 14 วันนับหลังจากวันที่รับแจ้งเรื่อง</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินให้ร้านค้าตามรอบบัญชีที่กำหนด</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าธรรมเนียมอื่น</p>
								</td>
								<td>
									<p>YELLOWTIRE : รับผิดชอบค่าธรรมเนียม (ถ้ามี)</p>
								</td>
								<td>
									<p>&nbsp;</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<ol start="4">
					<li>
						<strong>วันที่ติดตั้ง</strong>
						<strong> - กรณีร้านค้า ไม่มีสินค้า</strong>
					</li>
				</ol>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>ประเภทการชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ยกเลิกรายการสั่งซื้อ</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ด้วยการโอน</p>
								</td>
								<td>
									<p>ร้านค้า จ่ายให้ YELLOWTIRE 5%</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ด้วยการโอน <br /> *ลูกค้ายังต้องผ่อนชำระกับบัตร </p>
								</td>
								<td>
									<p>ร้านค้า จ่ายให้ YELLOWTIRE <br /> 8% (ผ่อน 3 เดือน) <br /> 9% (ผ่อน 4 เดือน) <br /> 10% (ผ่อน 6 เดือน) <br /> 13% (ผ่อน 10 เดือน) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ด้วยการโอน</p>
								</td>
								<td>
									<p>ร้านค้า จ่ายให้ YELLOWTIRE 5%</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาคืนเงิน</p>
								</td>
								<td>
									<p>ภายใน 14 วันนับหลังจากวันที่รับแจ้งเรื่อง</p>
								</td>
								<td>
									<p>&nbsp;</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>นโยบายนี้ปรับปรุงล่าสุดเมื่อวันที่ &nbsp;1 สิงหาคม 2566</p>
				<br />
				<i>บริษัท เยลโลไทร์ จำกัด</i>
				<br />
				<i>อาคารรสา ทู ชั้น 18</i>
				<br />
				<i>เลขที่ 1818 ถนนเพชรบุรี</i>
				<br />
				<i>แขวงมักกะสัน เขตราชเทวี</i>
				<br />
				<i>กรุงเทพมหานคร 10400 ประเทศไทย</i>
				<br />
				<i>โทรศัพท์ : +66 (0) 2026 7407</i>
				<br />
				<i>อีเมล : info@yellowtire.com</i>
				<br />
				<i>เว็บไซต์ : https://www.yellowtire.com</i>
				<br />
				<i>เฟซบุ๊ก www.facebook.com/yellowtire&nbsp;</i>
				<br />
				<br />
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบติดตั้ง : สำหรับผู้ขาย | YELLOWTiRE';
		const desc = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบติดตั้ง : สำหรับผู้ขาย YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>